@mixin respond($breakpoint) {
  @if ($breakpoint == medium) {
    @media (max-width: 1024px) {
      @content;
    }
  }
  @if ($breakpoint == small) {
    @media (max-width: 470px) {
      @content;
    }
  }
}

.button {
  // font-family: "GothamBold";
  font-style: normal;
  font-weight: 500;
  font-size: 0.8vw;
  line-height: 22px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  text-transform: uppercase;
  padding: 4% 5%;
  // width: 10vw;
  min-width: 10vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  border: none;
  cursor: pointer;
  button {
    margin-right: 2%;
  }
}
.editar {
  font-style: normal;
  font-weight: 500;
  font-size: 0.8vw;
  line-height: 16px;
  display: flex;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.containerBut{
  width: 100%;
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: center;
  padding: 8px;
  span {
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
}