.containerPagination {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  .disabled{
    opacity: 0;
    cursor: default;
  }
  input {
    border: none;
    outline: none;
    width: 50px;
    height: 70%;
    margin-right: 10px;
  }
  p {
    margin: 0 10px 0 10px;
    font-size: 16px;
  }
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    cursor: pointer;
    :hover {
      opacity:0.8;
    }
  }
}
