
  .contSearch{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    border-radius: 3px;
    border:rgba(191, 191, 191, 0.3);
    cursor: pointer;
    :hover {
      background-color: red;
      border-radius: 3px;
    }
    .searchButton{
      font-family: 'Roboto';
      font-size: 16px;
      padding: 10px;
      margin-left: 10px;
      outline: none;
      border: none;
      background-color: transparent;
    }
  }
  .containerInput {
    background: rgba(191, 191, 191, 0.3);  
    width: 238px;
    height: 40px;
    padding-left: 24px;
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: row;
    border: 1px solid #ccc;
    border-radius: 3px;
    label {
      font-size: 18px;
    }
    input {
      background-color: transparent;
      width: 100%;
      border: none;
      outline: none;
      margin-left: 24px;
      font-size: 16px;
      line-height:21px;
      height: 40px;
    }
    select {
      width: 50%;
      height: 30px;
      outline: none;
      background-color: #dfe;
      border: 0;
      border-radius: 3px;
    }
    .containerImg{
      width: 100px;
      img{
        margin-right: 20px;
        cursor: pointer;
      }
      span.material-icons {
        margin: 4px 8px;
        &:hover {
          cursor:pointer;
          opacity: 0.7;
        }
      }
    }
  }
